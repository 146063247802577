import {
  GroupsBy,
  Periods,
  SubMetrics,
} from 'src/app/shared/constants/filters.constants';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { TrendTileDefinition } from '../../entities/trend-tile-definition';

export const TrendTileConstants: Record<string, TrendTileDefinition> = {
  Sales: new TrendTileDefinition({
    title: 'Sales Trend',
    type: MetricType.Sales,
  }),
  Revenue: new TrendTileDefinition({
    title: 'Revenue Trend',
    type: MetricType.Revenue,
  }),
  Cci: new TrendTileDefinition({
    title: 'Delivered CCI Trend',
    type: MetricType.Cci,
    allowPercentage: true,
  }),
};

export const TrendFiltersConstants = {
  Filters: {
    Periods: [
      new TextValuePair({
        text: Periods.Quarter.text,
        value: Periods.Quarter.id,
      }),
      new TextValuePair({
        text: Periods.Month.text,
        value: Periods.Month.id,
      }),
    ],
    SubMetrics: (selectedFilters: SelectedFilters) => {
      return [
        new TextValuePair({
          text: selectedFilters.projection.getText(),
          value: SubMetrics.Projection,
        }),
        new TextValuePair({
          text: SubMetrics.Plan.toUpperCase(),
          value: SubMetrics.Plan,
        }),
      ];
    },
    GroupsBy: [
      new TextValuePair({
        text: GroupsBy.Composition.text,
        value: GroupsBy.Composition.id,
      }),
      new TextValuePair({
        text: GroupsBy.ServiceGroup.text,
        value: GroupsBy.ServiceGroup.id,
      }),
      new TextValuePair({
        text: GroupsBy.ServiceDimension.text,
        value: GroupsBy.ServiceDimension.id,
      }),
      new TextValuePair({
        text: GroupsBy.ClientGroup.text,
        value: GroupsBy.ClientGroup.id,
      }),
      new TextValuePair({
        text: GroupsBy.Market.text,
        value: GroupsBy.Market.id,
      }),
      new TextValuePair({
        text: GroupsBy.Location.text,
        value: GroupsBy.Location.id,
      }),
      new TextValuePair({
        text: GroupsBy.WMU.text,
        value: GroupsBy.WMU.id,
      }),
      new TextValuePair({
        text: GroupsBy.Industry.text,
        value: GroupsBy.Industry.id,
      }),
    ],
  },
};

export const TrendSubMetricsConfigConstants = {
  Current: {
    text: SubMetrics.Current,
  },
  Projection: {
    text: SubMetrics.Projection,
    borderDash: [2, 2],
  },
  Plan: {
    text: SubMetrics.Plan,
    borderDash: [0, 0],
  },
  Forecast: {
    text: SubMetrics.Forecast,
    borderDash: [8, 6],
  },
};

export const TrendColoursConstants = {
  GeneratedGroups: [
    GroupsBy.Composition.id,
    GroupsBy.ServiceGroup.id,
    GroupsBy.Market.id,
    GroupsBy.WMU.id,
    GroupsBy.ServiceDimension.id,
  ],
  Defined: [
    new TextValuePair({
      value: 'CON',
      text: '#C70039',
    }),
    new TextValuePair({
      value: 'SC',
      text: '#DAF7A6',
    }),
    new TextValuePair({
      value: 'TC',
      text: '#FF0200',
    }),
    new TextValuePair({
      value: 'MC',
      text: '#FF0200',
    }),
    new TextValuePair({
      value: 'IMS',
      text: '#FF5733',
    }),
    new TextValuePair({
      value: 'BPMS',
      text: '#FEFF3A',
    }),
    new TextValuePair({
      value: 'AMS',
      text: '#900C3F',
    }),
    new TextValuePair({
      value: 'SI',
      text: '#FFC300 ',
    }),
    new TextValuePair({
      value: 'Strategy & Con',
      text: '#8cc63f',
    }),
    new TextValuePair({
      value: 'Tech Services',
      text: '#00bcee',
    }),
    new TextValuePair({
      value: 'AppSvces',
      text: '#00bcee',
    }),
    new TextValuePair({
      value: 'Operations',
      text: '#9b2f9b',
    }),
    new TextValuePair({
      value: 'Other',
      text: '#AF00FD',
    }),
    new TextValuePair({
      value: 'N/A',
      text: '#d1d1d1',
    }),
    new TextValuePair({
      value: 'Total',
      text: '#E88D46',
    }),
    new TextValuePair({
      value: 'CCI%',
      text: '#E88D46',
    }),
    new TextValuePair({
      value: 'Plan',
      text: '#000',
    }),
    new TextValuePair({
      value: 'Projection',
      text: '#000',
    }),
    new TextValuePair({
      value: 'Actuals',
      text: '#AF00FD',
    }),
    new TextValuePair({
      value: 'Wins (this Month)',
      text: '#00A3FF',
    }),
    new TextValuePair({
      value: 'Backlog',
      text: '#939393',
    }),
    new TextValuePair({
      value: 'Qual Proj Opps',
      text: '#0601FE',
    }),
    new TextValuePair({
      value: 'Unqual Proj Opps',
      text: '#FE017F',
    }),
    new TextValuePair({
      value: 'Speculative',
      text: '#2AFF00',
    }),
    new TextValuePair({
      value: 'EMEA',
      text: '#FF0000',
    }),
    new TextValuePair({
      value: 'Asia Pacific',
      text: '#00FF02',
    }),
    new TextValuePair({
      value: 'Americas',
      text: '#0601FE',
    }),
    new TextValuePair({
      value: 'Accenture',
      text: '#AF00FD',
    }),
    new TextValuePair({
      value: 'Avanade',
      text: '#FF8900',
    }),
  ],
  Random: [
    new TextValuePair({
      value: 'randColor1',
      text: '#FEFF3A',
    }),
    new TextValuePair({
      value: 'randColor2',
      text: '#00A3FF',
    }),
    new TextValuePair({
      value: 'randColor3',
      text: '#FF00F7',
    }),
    new TextValuePair({
      value: 'randColor4',
      text: '#B5B202',
    }),
    new TextValuePair({
      value: 'randColor5',
      text: '#0601FE',
    }),
    new TextValuePair({
      value: 'Other',
      text: '#AF00FD',
    }),
  ],
};
